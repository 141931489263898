<template>
    <div class="container">
        <!-- 组件 -->
        <div class="tools">
             <div :class="['toolitem',chooseset==index?'tool_active':'']"  v-for="(item, index) in componentArr" :key="index" @click="changeSet(index)">
                <img :src="imgH + 'diyComponentsImg/'+item.icon" alt="icon" />
                <p>{{ item.name }}</p>
            </div>
        </div>
        <!-- 展示 -->
        <div class="pane">
            <div class="pane_phone">
                <div class="pane_main">
                    <img :src="imgH+'multiversion_phoneheader.png'" class="image">
                    <img :src="imgH+'multiversion_brand.png'" class="image">
                    <div class="content thin_scroll_column">
                        <!-- 轮播图 -->
                        <div :class="['carousel_box',chooseset==0?'component_checked':'component_canset']" @click="changeSet(0)">
                            <div class="carousel">
                                <el-carousel height="210px">
                                    <el-carousel-item v-for="(item,index) in carousel" :key="index">
                                        <img :src="item" alt="img_url">
                                    </el-carousel-item>
                                </el-carousel>
                            </div>
                        </div>
                        <!-- 品牌故事 -->
                        <div :class="['part',chooseset==1?'component_checked':'component_canset']" @click="changeSet(1)">
                            <div class="part_title" style="border-bottom: 1px solid #EDEDED;">{{brand_story.title}}</div>
                            <div class="story" v-html="brand_story.content"></div>
                        </div>
                        <!-- 品牌图册 -->
                        <div :class="['part',chooseset==2?'component_checked':'component_canset']" @click="changeSet(2)">
                            <div class="part_title">{{brand_image.title}}</div>
                            <div class="carousel">
                                <el-carousel height="259px">
                                    <el-carousel-item v-for="(item,index) in brand_image.list" :key="index">
                                        <img :src="item" alt="img_url">
                                    </el-carousel-item>
                                </el-carousel>
                            </div>
                        </div>
                        <!-- 商家信息 -->
                        <div class="shopinfo">
                            <div class="shopinfo_row">
                                <div class="shopinfo_row_left">店铺电话</div>
                                <div class="shopinfo_row_left">{{shopinfo.contact_number}}</div>
                            </div>
                            <div class="shopinfo_row">
                                <div class="shopinfo_row_left">营业时间</div>
                                <div class="shopinfo_row_left">12:00-18:00</div>
                            </div>
                            <div class="shopinfo_row">
                                <div class="shopinfo_row_left">店铺支持</div>
                                <div class="shopinfo_row_left">堂食</div>
                            </div>
                            <div class="shopinfo_row" style="border:none;">
                                <div class="shopinfo_row_left">门店地址</div>
                            </div>
                            <baidu-map @ready="handler" :scroll-wheel-zoom="true" :ak="$ak" class="map" v-if="shopinfo"></baidu-map>
                            <div class="address">{{address_before}}{{shopinfo.address}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 设置 -->
        <div class="setting">
            <div class="setting_header">{{componentArr[chooseset].name}}</div>
            <div style="font-size: 14px;">
                <carousel v-if="chooseset==0" :carousel="carousel"></carousel>
                <story v-if="chooseset==1" :brand_story="brand_story"></story>
                <brandimages v-if="chooseset==2" :brand_image="brand_image"></brandimages>
            </div>
        </div>
    </div>
</template>

<script>
import { imgUrlHead } from '@/util/config.js'
import carousel from '../components/carousel.vue'
import { BaiduMap } from 'vue-baidu-map'
import story from '../components/story.vue'
import brandimages from '../components/brandimages.vue'
export default {
    components:{
        carousel,
        BaiduMap,
        story,
        brandimages
    },
    data() {
        return {
            imgH: imgUrlHead,
            componentArr: [
                { name: '轮播图', icon: 'headerbg.png' },
                { name: '品牌故事', icon: 'noticeBar.png' },
                { name: '品牌图册', icon: 'allGoods.png' },
            ],
            chooseset:0,
            carousel:['https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/changImg.png'],
            carousel_active:0,
            brand_story:{
                title:'品牌故事',
                content:'<p>这是品牌故事</p>'
            },
            brand_image:{
                title:'品牌图册',
                list:['https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/changImg.png']
            },
            map:'',
            BMap:'',
            address_before:'',
            hasdata:false
        }
    },
    created(){
        this.getPageInfo()
    },
    computed: {
        shopinfo(){
            return this.$parent.shopinfo
        }
    },
    methods:{
        // 重置数据
        resetData(obj){
            this[obj.data][obj.attr] = null
        },
         // 改变设置组件
        changeSet(index){
            this.chooseset = index
        },
        handler({ BMap, map }) {
            this.map = map
            this.BMap = BMap
            let point = new this.BMap.Point(this.shopinfo.longitude, this.shopinfo.latitude)
            map.centerAndZoom(point, 15)
            let marker = new this.BMap.Marker(point)
            map.addOverlay(marker);
            var gc = new BMap.Geocoder();
            gc.getLocation(point, (rs)=> {
                this.address_before = rs.addressComponents.province+rs.addressComponents.city+rs.addressComponents.district
            });
        },
        // 获取装修数据
        getPageInfo(){
            this.$axios.post(this.$api.catering.diypageList, {
                type:2
            }).then((res) => {
                if(res.code==0){
                    if(res.result.page_values){
                        let info = res.result.page_values
                        this.carousel = info.carousel
                        this.brand_story = info.brand_story
                        this.brand_image = info.brand_image
                        this.hasdata = true
                    }
                }
            })
        },
        // 保存装修数据
        savePageInfo(){
            let info = {
                carousel:this.carousel,
                brand_story:this.brand_story,
                brand_image:this.brand_image
            }
            let data = {
                page_name:'品牌故事',
                page_type:'2',
                show_type:'2,3,4,5,6,7',
                page_value:info
            }
            if(this.hasdata){
                data.type = '2'
            }
            this.$axios.post(this.$api.catering.diypage, data).then((res) => {
                 if(res.code==0){
                    this.$message.success('保存成功')
                    this.getPageInfo()
                }else {
                    this.$message.error(res.msg)
                }
            })
        }
    }
}
</script>

<style scoped lang="less">
    .container{
        display: flex;
        height: 100%;
        .tools{
            flex: 0 0 300px;
            background-color: #fafafa;
            box-sizing: border-box;
            padding: 30px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-content: flex-start;
            .toolitem{
                width: 40%;
                padding: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                cursor: pointer;
                margin-bottom: 20px;
                font-size: 14px;
                box-sizing:initial;
                &:hover{
                    background-color: #E8F1FB;
                }
            }
            .tool_active{
                background-color: #E8F1FB;
            }
        }
        .pane{
            flex: 1;
            height: 100%;
            min-width: 411px;
            display: flex;
            justify-content: center;
            font-size: 14px;
            margin-left: 6px;
            .pane_phone{
                width: 411px;
                height: 853px;
                margin-top: 6px;
                background-size: 100% 100%;
                background-image: url(https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/phonebg.png);
                position: relative;
                overflow-y: auto;
            }
            .pane_main{
                width: 375px;
                height: 720px;
                margin: 60px 0 0 16px;
                display: flex;
                flex-direction: column;

                .content{
                    flex:1;
                    overflow-y: auto;
                }
                .carousel_box{
                    border-top: 10px solid #F5F5F5;
                    padding: 2px;
                }
                .carousel{
                    position: relative;
                    z-index: 100;
                    box-sizing: initial;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .part{
                    border-top: 10px solid #F5F5F5;
                    padding: 24px 15px;
                    .part_title{
                        padding-bottom: 12px;
                    }
                    .story{
                        font-size: 13px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #666666;
                        line-height: 22px;
                        /deep/p{
                            padding: 0.8em 0;
                        }
                    }
                }
                .shopinfo{
                    border-top: 10px solid #F5F5F5;
                    padding-bottom: 20px;
                    .shopinfo_row{
                        height: 60px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-size: 14px;
                        font-family: Source Han Sans CN;
                        font-weight: 500;
                        color: #333333;
                        margin: 0 15px;
                        border-bottom: 1px solid #EDEDED;
                    }
                    .map{
                        width: 100%;
                        height: 200px;
                        /deep/.BMap_cpyCtrl{
                            display: none;
                        }
                    }
                    .address{
                        padding: 15px;
                        font-size: 14px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #666666;
                        line-height: 21px;
                    }
                }
                .image{
                    width: 375px;
                    height: auto;
                }
            }
        }
        .setting{
            flex-shrink: 0;
            width: 596px;
            height: 100%;
            border: 1px solid #e6e6e6;
            box-sizing: border-box;
            padding-left: 20px;
            .setting_header{
                border-bottom: 1px solid #eee;
                height: 70px;
                display: flex;
                align-items: center;
                padding: 0 30px;
            }
        }
    }
</style>
